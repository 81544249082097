import {
    USER_LOADING,
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    SET_PROJECT,
    CREATE_PROJECT,
    DELETE_PROJECT
} from '../actions/types'

const initialState = {
    token: localStorage.getItem('token'),
    IsAuthenticated: false,
    isLoading: false,
    user: null,
}


export default function ReducerFunction(state = initialState, action) {
    switch (action.type) {
        case USER_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case USER_LOADED:
            return {
                ...state,
                IsAuthenticated: true,
                isLoading: false,
                user: action.payload
            }
        case LOGIN_SUCCESS:
        case REGISTER_SUCCESS:
            localStorage.setItem('token', action.payload.token)
            return {
                ...state,
                ...action.payload,
                IsAuthenticated: true,
                isLoading: false
            }
        case AUTH_ERROR:
        case LOGOUT_SUCCESS:
        case LOGIN_FAIL:
        case REGISTER_FAIL:
            localStorage.removeItem('token');
            return {
                ...state,
                token: null,
                user: null,
                IsAuthenticated: false,
                isLoading: false
            }
        case SET_PROJECT:
            
            return {
                ...state,           
                user: {
                    ...state.user,
                    profile: {
                        ...state.user.profile,
                        currentTextProject: action.payload.currentTextProject,
                        currentGraphProject: action.payload.currentGraphProject,
                        currentTwitterProject: action.payload.currentTwitterProject
                    }
                }
            }
        case CREATE_PROJECT:
            return {
                ...state,
                user: {
                    ...state.user,
                    projects: action.payload
                }
            }
        case DELETE_PROJECT:
            return {
                ...state,
                user: {
                    ...state.user,
                    profile: {
                        ...state.user.profile,
                        currentTextProject: action.payload.profile.currentTextProject,
                        currentGraphProject: action.payload.profile.currentGraphProject,
                        currentTwitterProject: action.payload.profile.currentTwitterProject
                    },
                    projects:{
                        ...state.user.projects,
                        graphs:action.payload.projects.graphs,
                        graph2s:action.payload.projects.graph2s,
                        twitters:action.payload.projects.twitters,
                    }
                }
            }
        default:
            return state;
    }
}