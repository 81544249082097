import React, { Component } from 'react'

export default class Test2 extends Component {
    constructor(props) {
        super(props)

        this.state = {
            element: null,
        }
    }
    static propTypes = {}

    componentDidMount() {
        const script = document.createElement("script");
        script.src = "https://embed.reddit.com/widgets.js";
        script.async = true;
        document.body.appendChild(script);
    }


    render() {
        return (
            <div className='redditPosts'>
                <blockquote className="reddit-embed-bq" style={{ height: "500px" }} data-embed-height="500">
                    <a href="https://www.reddit.com/r/canada/comments/12aifev/judge_warned_alleged_ttc_killer_a_threat_without/" >reddit</a>
                </blockquote>
                <blockquote className="reddit-embed-bq" style={{ height: "500px" }} data-embed-height="500">
                    <a href="https://www.reddit.com/r/canada/comments/12ansdc/jeremy_hansen_announced_as_the_first_canadian_to/" >reddit</a>
                </blockquote>
                <blockquote className="reddit-embed-bq" style={{ height: "500px" }} data-embed-height="500">
                    <a href="https://www.reddit.com/r/canada/comments/12aoc3u/opinion_why_are_we_not_incentivizing_exercise_for/" >reddit</a>
                </blockquote>
            </div>
        )
    }
}



