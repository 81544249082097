import React, { Component, lazy, Suspense } from 'react';
import Test from "./Test"
import Test2 from "./Test2"
import Test3 from "./Test3"


import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";


import PrivateRoute from './PrivateRoute'
import { loadUser } from '../actions/auth';
import { Provider } from 'react-redux';
import store from '../store';

import '../styles.scss'

export default class App extends Component {
  constructor(props) {
    super(props);
    store.dispatch(loadUser())
  }


  render() {
    const SpacingCard = lazy(() => import('./BaseComponents/SpacingCard'))
    const LandingPage = lazy(() => import('./LandingPage/landing-page'))
    const Login = lazy(() => import('./Accounts/Login'))
    const Register = lazy(() => import('./Accounts/Register'))

    const Hub = lazy(() => import("./GraphVisualization/Hub"))

    const Input = lazy(() => import('./TextAnalysis/Input'))
    const Statistiche = lazy(() => import('./TextAnalysis/Statistiche'))
    const TerminiAttenzione = lazy(() => import('./TextAnalysis/TerminiAttenzione'))
    const CostruzioneGrafo = lazy(() => import('./TextAnalysis/CostruzioneGrafo'))
    const TerminiSensibili = lazy(() => import('./TextAnalysis/TerminiSensibili'))
    const PosNer = lazy(() => import('./TextAnalysis/PosNer'))
    const AnalisiGrafo = lazy(() => import('./TextAnalysis/AnalisiGrafo'))
    const Riassunto = lazy(() => import('./TextAnalysis/Riassunto'))
    const TreD = lazy(() => import('./TextAnalysis/TreD'))
    const VR = lazy(() => import('./TextAnalysis/VR'))

    const Input2 = lazy(() => import('./GraphAnalysis/Input2'))
    const Simulation = lazy(() => import('./GraphAnalysis/Simulation'))

    const Input3 = lazy(() => import('./Twitter/Input3'))

    const DataVisualization = lazy(() => import('./DataVisualization/Input4'))
    return (
      <>

        <Provider store={store}>
          <Suspense fallback={<div style={{ margin: "auto" }}>Loading data from the server</div>}>
            <Router basename='/'>

              <Switch >
                <Route exact path="/test/twitter" component={Test} />
                <Route exact path="/test/reddit" component={Test2} />
                <Route exact path="/test/looker" component={Test3} />
                <Route exact path="/" component={LandingPage} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/register" component={Register} />

                <PrivateRoute exact path="/dashboard" component={(props) => <SpacingCard  {...props} />} />

                <PrivateRoute exact path="/textanalysis/input/" component={(props) => <Input  {...props} />} />
                <PrivateRoute exact path="/textanalysis/statistiche" component={(props) => <Statistiche {...props} />} />
                <PrivateRoute exact path="/textanalysis/terminiAttenzione" component={(props) => <TerminiAttenzione   {...props} />} />
                <PrivateRoute exact path="/textanalysis/costruzioneGrafo" component={(props) => <CostruzioneGrafo  {...props} />} />
                <PrivateRoute exact path="/textanalysis/terminiSensibili" component={(props) => <TerminiSensibili   {...props} />} />
                <PrivateRoute exact path="/textanalysis/posner" component={(props) => <PosNer  {...props} />} />
                <PrivateRoute exact path="/textanalysis/networkx" component={(props) => <AnalisiGrafo title="Text Network Analysis" {...props} />} />
                <PrivateRoute exact path="/textanalysis/grafo" component={(props) => <Hub api_endpoint="getData" {...props} />} />
                <PrivateRoute exact path="/textanalysis/nergraph" component={(props) => <Hub api_endpoint="calculateNerGraph"{...props} />} />
                <PrivateRoute exact path="/textanalysis/riassunto" component={(props) => <Riassunto  {...props} />} />
                <PrivateRoute exact path="/textanalysis/3d" component={(props) => <TreD  {...props} />} />
                <PrivateRoute exact path="/textanalysis/vr" component={(props) => <VR  {...props} />} />

                <PrivateRoute exact path="/graphanalysis/input" component={(props) => <Input2  {...props} />} />
                <PrivateRoute exact path="/graphanalysis/networkx" component={(props) => <AnalisiGrafo title="Network Analysis" {...props} />} />
                <PrivateRoute exact path="/graphanalysis/grafo" component={(props) => <Hub api_endpoint="getData" {...props} />} />
                <PrivateRoute exact path="/graphanalysis/simulation" component={(props) => <Simulation  {...props} />} />
                <PrivateRoute exact path="/graphanalysis/3d" component={(props) => <TreD  {...props} />} />
                <PrivateRoute exact path="/graphanalysis/vr" component={(props) => <VR  {...props} />} />

                <PrivateRoute exact path="/twitter/input" component={(props) => <Input3  {...props} />} />
                <PrivateRoute exact path="/twitter/networkx" component={(props) => <AnalisiGrafo title="Twitter Analysis" {...props} />} />
                <PrivateRoute exact path="/twitter/grafo" component={(props) => <Hub api_endpoint="getData" {...props} />} />
                <PrivateRoute exact path="/twitter/simulation" component={(props) => <Simulation  {...props} />} />

                <PrivateRoute exact path="/datavisualization/input" component={(props) => <DataVisualization  {...props} />} />
              </Switch>
            </Router>
          </Suspense>
        </Provider>
      </>
    );
  }
}