import * as React from "react";
import Box from "@mui/material/Box";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Input from "@mui/material/Input";
import TextField from "@mui/material/TextField";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { IconButton } from '@mui/material';



export function TitleBox(props) {
  return (
    <Box>
      <Typography variant="h4" align="center">
        {props.text}
      </Typography>
    </Box>
  );
}


export function InputFile(props) {
  const handleChange = (e) => {
    const filePath = e.target.value.split("\\").at(-1);
    props.function(e.target.files[0], props.fileId, filePath);
  };

  return (
    <Box sx={{ display: "none" }}>
      <Input
        type="file"
        id={props.id}
        inputProps={{ accept: props.accept }}
        onChange={handleChange}
      ></Input>
    </Box>
  );
}


export function TextAreaCustom(props) {

  return (
    <TextareaAutosize
      id={props.id}
      aria-label="minimum height"
      minRows={15}
      placeholder="Inserire il testo che si vuole caricare"
      style={{ width: "100%" }}
      readOnly={props.readOnly}
    />
  );
}


export function BtnCustom(props) {
  const variant = (props.variant) ? props.variant : "contained"

  const handleClick = (e) => {
    if (props.function) {
      props.function(e, props.identifier);
    }
  }


  return (
    <Button
      sx={props.sx}
      variant={variant}
      onClick={handleClick}
      id={props.id}
      style={props.style}
      disabled={props.disabled}
      fullWidth={props.fullWidth}
      size={props.size}
    >
      {props.text}
    </Button>
  );
}


export function TextFieldCustom(props) {

  const handleChange = (event) => {
    if (props.function) {
      props.function(event.target.value, props.identifier);
    }
  };

  return (
    <TextField
      style={props.style}
      fullWidth={props.fullWidth}
      type={props.type}
      label={props.label}
      defaultValue={props.defaultValue}
      variant={props.variant}
      onChange={(e) => handleChange(e)}
    />
  );
}

export function CustomIconButton(props) {
  const handleClick = (e) => {
    if (props.function) {
      props.function(props.identifier)
    }
  }

  return (
    <IconButton id="icon_change_type" onClick={handleClick}>
      <PlayArrowIcon />
    </IconButton>
  )
}
