import React from 'react'

function Test() {
    return (
        <>
        <div className='twitterContainer'>
            <a className="twitter-timeline" href="https://twitter.com/UnitedSpA1/lists/1643519827238068227?ref_src=twsrc%5Etfw">A Twitter List by UnitedSpA1</a> 
        </div>
        </>
    )
}

export default Test