import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import {access_sections} from "../utils/Sections_access"


const PrivateRoute = ({ component: Component, auth, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            const {location} = {...props} 
            const page_url=location.pathname.split("/")[1]
            if (auth.isLoading) {
                return <h2>loading...</h2>
            } else if (auth.IsAuthenticated) {
                return access_sections(auth.user.profile,page_url, Component, props)
            } else {
                return <Redirect to="/login" />
            }
        }}
    />
);

const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps)(PrivateRoute)