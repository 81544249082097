// mi serve per la versione desktop
import React from "react"; 

import ErrorBoundary from "../components/ErrorBoundary";

export function get_current_project(profile, url) {
  switch (url) {
    case "textanalysis":
      return profile.currentTextProject;
    case "graphanalysis":
      return profile.currentGraphProject;
    case "twitter":
      return profile.currentTwitterProject;
    case "datavisualization":
      return profile.currentDataVisualizationProject
    default:
      return null
  }
}

export function access_sections(profile, url, Component, props) {
  if (url === "textanalysis" && profile.textAnalysisAccess === false) {
    return <div>Non hai accesso a questa sezione</div>;
  }
  if (url === "graphanalysis" && profile.graphAnalysisAccess === false) {
    return <div>Non hai accesso a questa sezione</div>;
  }
  if (url === "twitter" && profile.twitterAccess === false) {
    return <div>Non hai accesso a questa sezione</div>;
  }
  if (url === "datavisualization" && profile.dataVisualizationAccess === false) {
    return <div>Non hai accesso a questa sezione</div>;
  }
  return <ErrorBoundary url={url} {...props}><Component url={url} {...props} /></ErrorBoundary>
}

export function get_current_projects(projects,profile,url) {
  switch (url) {
    case "textanalysis":
      return [
        projects.graphs,
        profile.currentTextProject,
      ];
    case "graphanalysis":
      return [
        projects.graph2s,
        profile.currentGraphProject,
      ];
    case "twitter":
      return [
        projects.twitters,
        profile.currentTwitterProject,
      ];
    case "datavisualization":
      return [
        projects.datavisualizations,
        profile.currentDataVisualizationProject,
      ];
    default:
      return [null, null];
  }
}
