import React from 'react'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import MenuBar from "./Bar/MenuBar";
import { withRouter } from 'react-router-dom';


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      if (this.state.hasError) {
        this.setState({ hasError: false });
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }


  static propTypes = {
    auth: PropTypes.object.isRequired,
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <>
        <MenuBar {...this.props} />
        <h1 style={{textAlign:"center"}}>"Qualche cosa è andato storto"</h1>
      </>
    }

    return this.props.children;
  }
}



const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default withRouter(connect(mapStateToProps)(ErrorBoundary));