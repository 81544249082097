import axios from "axios";
import {
    USER_LOADING,
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGOUT_SUCCESS,
    SET_PROJECT,
    CREATE_PROJECT,
    DELETE_PROJECT,
    PROJECT_ERROR
} from './types'

import {REACT_APP_API_URL} from '../constant'

export const loadUser = () => async (dispatch, getState) => {
    dispatch({ type: USER_LOADING })
    return axios.get(REACT_APP_API_URL+`auth/api/auth/user`, tokenConfig(getState))
        .then(res => {
            dispatch({ type: USER_LOADED, payload: res.data })
            return true
        })
        .catch(err => {
            dispatch({ type: AUTH_ERROR });
            return false
        })
}

export const login = (username, password) => async (dispatch) => {

    // headers
    const config = {
        headers: {
            'content-type': 'application/json'
        }
    }

    const body = JSON.stringify({ username, password })

    return axios.post(REACT_APP_API_URL+`auth/api/auth/login`, body, config)
        .then(res => {
            dispatch({ type: LOGIN_SUCCESS, payload: res.data })
            return [true,res]
        })
        .catch(err => {
            dispatch({ type: LOGIN_FAIL });
            return [false, err]
        })
}

export const register = ({ username, password, email }) => async (dispatch) => {
    // headers
    const config = {
        headers: {
            'content-type': 'application/json'
        }
    }

    const body = JSON.stringify({ username, email, password })

    return axios.post(REACT_APP_API_URL+`auth/api/auth/register`, body, config)
        .then(res => {
            dispatch({ type: REGISTER_SUCCESS, payload: res.data })
            return [true,res]
        })
        .catch(err => {
            dispatch({ type: REGISTER_FAIL });
            return [false,err]
        })
}

export const logout = () => async (dispatch, getState) => {
    return axios.post(REACT_APP_API_URL+`auth/api/auth/logout`, null, tokenConfig(getState))
        .then(res => {
            dispatch({ type: LOGOUT_SUCCESS })
            return [true,res]
        })
        .catch(err => {
            return [false,err]
        })
}

export const tokenConfig = getState => {
    // get token from state 
    const token = getState().auth.token;

    // headers
    const config = {
        headers: {
            'content-type': 'application/json'
        }
    }

    if (token) {
        config.headers['Authorization'] = `Token ${token}`;
    }

    return config
}

export const setProject = (owner, page, project) => async (dispatch, getState) => {
    const data = {
        'owner': owner,
        'page': page,
        'project': project
    }

    return axios.post(REACT_APP_API_URL+`api/setProjectValue`, data)
        .then(res => {
            if (res.status === 200) {
                console.log(res.data)
                dispatch({ type: SET_PROJECT, payload: res.data.profile })
                window.location.reload()
                return [true,res]
            }
        })
        .catch(err => {
            dispatch({ type: PROJECT_ERROR });
            return [false,err]
        })
}

export const createProject = (owner, page, project) => async (dispatch, getState) => {
    const data = {
        'owner': owner,
        'page': page,
        'project': project
    }

    return axios.post(REACT_APP_API_URL+`api/createNewProject`, data)
        .then(res => {
            dispatch({ type: CREATE_PROJECT, payload: res.data.projects })
            return [true,res]
        })
        .catch(err => {
            dispatch({ type: PROJECT_ERROR });
            return [false,err]
        })
}

export const deleteProject = (owner, page, project) => async (dispatch, getState) => {
    const data = {
        'owner': owner,
        'page': page,
        'project': project
    }

    return axios.post(REACT_APP_API_URL+`api/deleteProject`, data)
        .then(res => {
            console.log(res.data)
            dispatch({ type:DELETE_PROJECT, payload: res.data })
            return [true,res]
        })
        .catch(err => {
            dispatch({ type: PROJECT_ERROR });
            return [false,err]
        })
}
