export const USER_LOADING='USER_LOADING';
export const USER_LOADED='USER_LOADED';
export const AUTH_ERROR='AUTH_ERROR';
export const LOGIN_SUCCESS='LOGIN_SUCCESS';
export const LOGIN_FAIL='LOGIN_FAIL';
export const LOGOUT_SUCCESS='LOGOUT_SUCCESS';
export const REGISTER_SUCCESS='REGISTER_SUCCESS';
export const REGISTER_FAIL='REGISTER_FAIL';
export const SET_PROJECT='SET_PROJECT';
export const GET_PROJECT='GET_PROJECT';
export const PROJECT_ERROR='PROJECT_ERROR';
export const CREATE_PROJECT='CREATE_PROJECT';
export const DELETE_PROJECT='DELETE_PROJECT';



