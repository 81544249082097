import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { withModal } from "../BaseComponents/DialogCustom";
import { AppBar, Box, Toolbar, Typography, TextField, Button, IconButton } from "@mui/material"
import { Avatar, Drawer, List, Divider, ListItem, ListItemText } from "@mui/material"
import MenuIcon from '@mui/icons-material/Menu';
import IconButtonAlias from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import logobaia from '../../images/logo_baia.png'
import imageprofile from '../../images/image_profile.jpg'
import { logout, setProject, createProject, deleteProject } from '../../actions/auth'
import { get_current_projects } from '../../utils/Sections_access'
import { BtnCustom, } from '../BaseComponents/Generic'


export class MenuBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: this.props.url,
            owner: this.props.auth.user.username,
            active1: false,
            active2: false,
        }
        this.toggleDrawer1 = this.toggleDrawer1.bind(this);
        this.toggleDrawer2 = this.toggleDrawer2.bind(this);
    }

    static propTypes = {
        auth: PropTypes.object.isRequired,
        logout: PropTypes.func.isRequired,
        setProject: PropTypes.func.isRequired,
    };


    toggleDrawer1 = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        this.setState({ active1: open })
    };

    toggleDrawer2 = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        this.setState({ active2: open })
    };

    logoutWrapper = async () => {
        this.props.set_loading(true)
        const response = await this.props.logout()
        this.props.set_loading(false)

        if (!response[0]) {
            let message_error = "Errore durante il Logout"
            if (response[1].response) {
                if ("info" in response[1].response.data) {
                    message_error = response[1].response.data.info
                }
            }
            this.props.set_modal(true, message_error, "Errore Logout")
        }
    }

    createProjectWrapper = async () => {
        this.props.set_loading(true)
        const response = await this.props.createProject(this.state.owner, this.state.page, document.getElementById("projectName").value)
        this.props.set_loading(false)

        if (!response[0]) {
            let message_error = "Errore durante la creazione di un nuovo progetto"
            if (response[1].response) {
                if ("info" in response[1].response.data) {
                    message_error = response[1].response.data.info
                }
            }
            this.props.set_modal(true, message_error, "Errore creazione progetto")
        }
    }

    deleteProjectWrapper = async (text) => {
        this.props.set_loading(true)
        const response = await this.props.deleteProject(this.state.owner, this.state.page, text)
        this.props.set_loading(false)


        if (!response[0]) {
            let message_error = "Errore durante la cancellazione di un nuovo progetto"
            if (response[1].response) {
                if ("info" in response[1].response.data) {
                    message_error = response[1].response.data.info
                }
            }
            this.props.set_modal(true, message_error, "Errore cancellazione progetto")
        }
        window.location.reload()
    }

    setProjectWrapper = async (target) => {
        this.props.set_loading(true)
        const response = await this.props.setProject(this.state.owner, this.state.page, target)
        this.props.set_loading(false)

        if (!response[0]) {
            let message_error = "Errore durante la selezione di un progetto"
            if (response[1].response) {
                if ("info" in response[1].response.data) {
                    message_error = response[1].response.data.info
                }
            }
            this.props.set_modal(true, message_error, "Errore selezione progetto")
        }
    }

    render() {
        const { user } = this.props.auth;

        const [listaProjects, currentProject] = get_current_projects(this.props.auth.user.projects, this.props.auth.user.profile, this.props.url)

        const Home1 = (
            <div id="menu-bar">
                {this.props.auth.user.profile.section_access.includes('input') && <Link to="/textanalysis/input" className="nav-link" > Input </Link>}
                {this.props.auth.user.profile.section_access.includes('statistiche') && <Link to="/textanalysis/statistiche" className="nav-link" >Statistiche</Link>}
                {this.props.auth.user.profile.section_access.includes('termini_sensibili') && <Link to="/textanalysis/terminiSensibili" className="nav-link" >Termini Sensibili</Link>}
                {this.props.auth.user.profile.section_access.includes('termini_attenzione') && <Link to="/textanalysis/terminiAttenzione" className="nav-link" >Termini Di Attenzione</Link>}
                {this.props.auth.user.profile.section_access.includes('costruzione_grafo') && <Link to="/textanalysis/costruzioneGrafo" className="nav-link" >Costruzione del grafo</Link>}
                {this.props.auth.user.profile.section_access.includes('pos_ner') && <Link to="/textanalysis/posner" className="nav-link" >POS-NER</Link>}
                {this.props.auth.user.profile.section_access.includes('analisi_grafo') && <Link to="/textanalysis/networkx" className="nav-link" >Analisi Grafo</Link>}
                {this.props.auth.user.profile.section_access.includes('grafo') && <Link to="/textanalysis/grafo" className="nav-link" >Grafo</Link>}
                {this.props.auth.user.profile.section_access.includes('ner_graph') && <Link to="/textanalysis/nergraph" className="nav-link" >NER_Graph</Link>}
                {this.props.auth.user.profile.section_access.includes('riassunto') && <Link to="/textanalysis/riassunto" className="nav-link" >Riassunto</Link>}
                {this.props.auth.user.profile.section_access.includes('tred') && <Link to="/textanalysis/3d" className="nav-link" >3D</Link>}
                {this.props.auth.user.profile.section_access.includes('vr') && <Link to="/textanalysis/vr" className="nav-link" >VR</Link>}
            </div>
        )

        const Home2 = (
            <div id="menu-bar">
                {this.props.auth.user.profile.section_access.includes('input2') && <Link to="/graphanalysis/input" className="nav-link" >Input</Link>}
                {this.props.auth.user.profile.section_access.includes('analisigrafo2') && <Link to="/graphanalysis/networkx" className="nav-link" >Analisi Grafo</Link>}
                {this.props.auth.user.profile.section_access.includes('grafo2') && <Link to="/graphanalysis/grafo" className="nav-link" >Grafo</Link>}
                {this.props.auth.user.profile.section_access.includes('simulation2') && <Link to="/graphanalysis/simulation" className="nav-link" >Simulation</Link>}
                {this.props.auth.user.profile.section_access.includes('tred2') && <Link to="/graphanalysis/3d" className="nav-link" >3D</Link>}
                {this.props.auth.user.profile.section_access.includes('vr2') && <Link to="/graphanalysis/vr" className="nav-link" >VR</Link>}
            </div>
        )

        const Home3 = (
            <div id="menu-bar">
                {this.props.auth.user.profile.section_access.includes('input3') && <Link to="/twitter/input" className="nav-link" >Input</Link>}
                {this.props.auth.user.profile.section_access.includes('input3') && <Link to="/twitter/networkx" className="nav-link" >Analisi Grafo</Link>}
                {this.props.auth.user.profile.section_access.includes('input3') && <Link to="/twitter/grafo" className="nav-link" >Grafo</Link>}
                {this.props.auth.user.profile.section_access.includes('input3') && <Link to="/twitter/simulation" className="nav-link" >Simulation</Link>}
            </div>
        )

        const Home4 = (
            <div id="menu-bar">
                {this.props.auth.user.profile.section_access.includes('input4') && <Link to="/datavisualization/input" className="nav-link" >Input</Link>}
            </div>
        )


        const HAMBURGER_PROJECT_NAME = (
            <IconButton
                size="large"
                edge="start"
                color="inherit"
                onClick={this.toggleDrawer1(true)}
                aria-label="menu"
                sx={{ mr: 2 }}
            >
                <MenuIcon />
                <Typography variant="h6" component="div" sx={{ ml: 2 }}>
                    {currentProject}
                </Typography>
            </IconButton>
        )


        const PROFILE = (
            <div id="right_menu">
                <Link to="/dashboard"><img src={logobaia} alt="" height="30" /></Link>
                <Box sx={{ mt: 1, mb: 1 }}>
                    Utente: {user.username}
                </Box>
                <BtnCustom text="Logout" function={this.logoutWrapper} id="btn_logout" />
            </div>
        );


        const LISTA_PROJECTS = (
            <Box id="left_menu" sx={{ m: 2 }}>
                <List >
                    {listaProjects.map((text, index) => {
                        const classNameString = (text === currentProject) ? "hamburgerProjects selectedProject" : "hamburgerProjects"
                        return (<ListItem className={classNameString} key={text}>
                            <ListItemText primary={text} onClick={(event) => this.setProjectWrapper(event.target.innerHTML)} />
                            <IconButtonAlias onClick={() => this.deleteProjectWrapper(text)}>
                                <DeleteIcon />
                            </IconButtonAlias>
                        </ListItem>)
                    })}
                </List>
                <Divider light />
                <TextField id="projectName" label="Nome Progetto" focused fullWidth={true} sx={{ mt: 2 }} />
                <Button variant="contained" fullWidth={true} sx={{ mt: 2 }} onClick={this.createProjectWrapper}>Aggiungi</Button>
            </Box>
        );

        return (

            <div id="MenuBar">

                <Drawer
                    anchor='left'
                    open={this.state.active1}
                    onClose={this.toggleDrawer1(false)}
                    PaperProps={{
                        sx: { maxWidth: "15%" },
                    }}
                >
                        {LISTA_PROJECTS}
                </Drawer>

                <Drawer
                    anchor='right'
                    open={this.state.active2}
                    onClose={this.toggleDrawer2(false)}
                    PaperProps={{
                        sx: { width: "15%" },
                    }}
                >
                        {PROFILE}
                </Drawer>


                <AppBar position="static" >
                    <Toolbar sx={{ justifyContent: 'space-between', display: "flex" }}>

                        {HAMBURGER_PROJECT_NAME}

                        {this.state.page === "textanalysis" && Home1}
                        {this.state.page === "graphanalysis" && Home2}
                        {this.state.page === "twitter" && Home3}
                        {this.state.page === "datavisualization" && Home4}

                        <Avatar alt={this.state.owner} src={imageprofile} onClick={this.toggleDrawer2(true)} />
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
    });
}

export default connect(mapStateToProps, { logout, setProject, createProject, deleteProject })(withModal(MenuBar));