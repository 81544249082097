import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';



const defaultValuesModal = {
  modalState: false,
  title: "",
  description: "",
}

const defaultValuesLoading = {
  isLoading: false,
  fnCloseLoading: null,
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const withModal = (WrappedComponent) => {
  class MyHOC extends React.Component {


    constructor(props) {
      super(props)
      this.state = {
        ...defaultValuesModal,
        ...defaultValuesLoading
      }
    }

    set_modal = (modalState, title, description) => {
      this.setState({ modalState, title, description })
    }

    set_loading = (isLoading, fnCloseLoading = null) => {
      this.setState({ isLoading, fnCloseLoading })
    }

    reset_modal = () => {
      this.setState({ ...defaultValuesModal })
    }
    reset_loading = () => {
      this.setState({ ...defaultValuesLoading })
    }


    render() {
      return <>
        <WrappedComponent {...this.props}
          set_modal={this.set_modal}
          set_loading={this.set_loading}
        />
        <NewDialogBox {...this.state} reset_modal={this.reset_modal} reset_loading={this.reset_loading} />
      </>
    }
  }

  return MyHOC;
}

const NewDialogBox = ({ modalState, title, description, isLoading, fnCloseLoading, reset_modal, reset_loading }) => {
 
  const [stateModal, setStateModal] = useState(modalState)
  const [stateIsLoading, setStateIsLoading] = useState(isLoading)

  useEffect(() => {
    setStateModal(modalState)
  }, [modalState])

  useEffect(() => {
    setStateIsLoading(isLoading)
  }, [isLoading])


  const handleCloseModal = () => {
    setStateModal(false)
    reset_modal()
  }

  const handleCloseLoading = ()=>{
    setStateIsLoading(false)
    reset_loading()
    fnCloseLoading()
  }


  return (
    <>
      <Dialog
        open={stateModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseModal}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText >
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Ok</Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: () => 1000,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        open={stateIsLoading}
      >
        <CircularProgress color="inherit" />
        {fnCloseLoading && <Button variant='contained' onClick={handleCloseLoading}>Stop</Button>}
      </Backdrop>
    </>
  );
}




